<template>
  <div class="card-body">
    <div class="row">
      <div class="col-md-12">
        <div class="about-info">
          <h4>
            Perfil del Paciente
            <span>
              <div class="dropdown dropdown-action">
                <a
                  href="javascript:;"
                  class="action-icon dropdown-toggle"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  ><i class="fa fa-ellipsis-v"></i></a>
                <div class="dropdown-menu dropdown-menu-end">
                  <router-link class="dropdown-item" 
                      :to="{ name: 'patient-edit', params: {id: this.id}}">Editar</router-link>
                </div>
              </div>
            </span>
          </h4>
        </div>

        <Spinner ref="Spinner" v-if="bSpinner"/>

        <div class="card-box profile-header" v-if="bSpinner == false">
          <div class="row">
            <div class="col-md-12">
              <div class="profile-view">
                <div class="profile-img-wrap">
                  <div class="profile-img">
                    <a href="javascript:;"
                      ><img class="avatar" src="@/assets/img/user.jpg" alt=""
                    /></a>
                  </div>
                </div>
                <div class="profile-basic">
                  <div class="row">
                    <div class="col-md-5">
                      <div class="profile-info-left">
                        <h3 class="user-name m-t-0 mb-0">{{ objPaciente.strNombreCompleto }}</h3>
                        <div class="staff-id">Sucursal: {{ objPaciente.strSucursal }} </div>
                        <div>
                          <small class="text-muted">Paciente : PX-#{{ id }}</small>
                        </div>
                        <div>
                          <small class="text-muted">Fecha ingreso : {{ objPaciente.strFechaCreacion }}</small>
                        </div>
                        <div>
                          <small class="text-muted">Usuario creación : {{ objPaciente.strUsuarioCreacion }}</small>
                        </div>
                        <!--div class="staff-msg">
                          <router-link to="/chat" class="btn btn-primary">Consultar</router-link
                          >
                        </div-->
                      </div>
                    </div>
                    <div class="col-md-7">
                      <ul class="personal-info">
                        <li>
                          <span class="title">Telefono:</span>
                          <span class="text"><a href="">{{ objPaciente.strTelefono }}</a></span>
                        </li>
                        <li>
                        <span class="title">Correo:</span>
                          <span class="text">{{ objPaciente.strCorreo }}</span>
                        </li>
                        <li>
                          <span class="title">Edad:</span>
                          <span class="text">{{ objPaciente.iEdad }}</span>
                        </li>
                        <li>
                          <span class="title">Genero:</span>
                          <span class="text">{{ objPaciente.strGenero }}</span>
                        </li>
                        <li>
                          <span class="title">Locación:</span>
                          <span class="text">{{ objPaciente.strLocacion }}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from '@/axios';
  import Spinner from '@/components/Spinner.vue';
  import { format } from 'date-fns';

  let objPaciente = {
    strNombreCompleto: '',
    strSucursal: '',
    strGenero: '',
    strLocacion: '',
    iEdad: '',
    fecha_nacimiento: '',
    strFechaCreacion: '',
  }
  export default {
    props: {
      id: {
        type: String,
        default: null,
      },
    },
    data() {
      return {
        bSpinner: false,
        objPaciente: objPaciente,
      };
    },
    components: {
      Spinner,
    },
    async created(){
      await this.loadData();
    },
    methods: {
      async loadData() {
        this.bSpinner = true;
        try {
          const objResponse = await axios.get(`/pacientes/${this.id}`);
          this.objPaciente = objResponse.data;

          let strCiudad = '', strLocalidad = '', strLocacion;
          this.objPaciente.strNombreCompleto = `${objResponse.data.nombre ?? ''} ${objResponse.data.apellido_paterno ?? ''} ${objResponse.data.apellido_materno ?? ''}`;
          this.objPaciente.iEdad = `${new Date().getFullYear() - new Date(objResponse.data.fecha_nacimiento).getFullYear()} años`;
          this.objPaciente.strGenero = objResponse.data.genero?.nombre ?? 'No disponible';
          this.objPaciente.strSucursal = objResponse.data.sucursal?.nombre ?? 'No disponible';
          this.objPaciente.strCorreo = objResponse.data.correo_electronico < 5 ? 
            'No disponible' : objResponse.data.correo_electronico;
          this.objPaciente.strTelefono = objResponse.data.telefono_celular ?? 'No disponible';

          strCiudad = this.objPaciente.ciudad?.nombre ?? 'No disponible';
          strLocalidad = this.objPaciente.localidad?.nombre ?? 'No disponible';

          if(strCiudad == 'No disponible' || strLocalidad == 'No disponible') strLocacion = 'No disponible';
          else strLocacion = `${this.objPaciente.ciudad?.nombre ?? ''}, ${this.objPaciente.localidad?.nombre ?? 'No disponible'}`;

          this.objPaciente.strFechaCreacion = objResponse.data.fecha_creacion ? format(new Date(objResponse.data.fecha_creacion), 'dd/MM/yyyy HH:mm') : 'No disponible';
          this.objPaciente.strUsuarioCreacion = objResponse.data.usuario_creacion ? `${objResponse.data.usuario_creacion.nombre} ${objResponse.data.usuario_creacion.apellido_paterno}` : 'No disponible';
          
          this.objPaciente.strLocacion = strLocacion
      
        } catch (error) {
          console.error(`Error al obtener paciente: `, error);
        }
        this.bSpinner = false;
      },
    },
  };
</script>
