<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb :title="strTitle" :text="strFormTitle" :path="strPath" />
      <physiocloud-component-form
        :bEdit="bEdit"
        :strFormTitle="strFormTitle"
        :strRecordId="strRecordId"
        :strApiRoute="strApiRoute"
        :lstFields="lstFields"
        :objForm="objForm" 
        @submit="handleSubmit" 
        @cancel="handleCancel"
      />
    </div>
  </div>
<delete></delete>
</template>
<script>
import axios from "@/axios";
import { format } from 'date-fns';

const lstFields = [
  { name: "id_tratamiento", label: "Tratamiento", type: 'select', required: true, options: [], strClass: "col-12 col-md-6 col-xl-4"},
  { name: "escala_dolor", label: "Escala de dolor", type: 'range', required: false, strClass: "col-12 col-md-6 col-xl-4"},
  { name: "id_tipo_consulta", label: "Tipo de consulta", type: 'select', required: true, options: [], strClass: "col-12 col-md-6 col-xl-4"},
  { name: "fecha_tratamiento", label: "Fecha de tratamiento", type: 'date', required: true, strClass: "col-12 col-md-6 col-xl-4"},
  { name: "comentarios", label: "Comentarios", type: 'textarea', required: true, strClass: "col-12 col-md-6 col-xl-4"},
  { name: "", label: "", type: '', strClass: "col-12 col-md-6 col-xl-4"},
  { name: "firma", label: "Firma", type: 'signature', strClass: "col-12 col-md-12 col-xl-12"},
];

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    parentId: {
      type: String,
      default: null,
    },
    recordId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      bEdit: this.isEdit,
      strTitle: "Paciente",
      strSubutitle: "Historia Clínica #",
      strRecordId: this.recordId,
      strPath: `/patient/${this.id}/profile`,
      strFormTitle: "Formulario de Consultas",
      strApiRoute: "/consultas",
      lstFields: lstFields,
      objForm: {},
    };
  },
  async created() {
    this.handleInitForm();
    this.handlePaciente();
    this.handleCatalogos();
    this.handleDataInit();
  },
  methods: {
    handleInitForm(){
      this.objForm = {
        bFirma: false,
        strImage: '',
        id_tratamiento: "",
        escala_dolor: "",
        id_tipo_consulta: "",
        id_historia_clinica: "",
        fecha_tratamiento: format(new Date(), 'yyyy-MM-dd'),
        comentarios: "",
        firma: "",
      };
    },
    async handleDataInit(){
      if(this.bEdit && this.strRecordId){
        try {
          const objResponse = await axios.get(`${this.strApiRoute}/${this.recordId}`);
          this.objForm = objResponse.data;
          const dtFechaTratamiento = new Date(objResponse.data.fecha_tratamiento).toISOString().split('T')[0];
          this.strTitle = `Historia Clínica # (${objResponse.data.paciente?.nombre ?? ''} ${objResponse.data.paciente?.apellido_paterno ?? ''} ${objResponse.data.paciente?.apellido_materno ?? ''})`;
          this.objForm.id_tratamiento = String(objResponse.data.id_tratamiento);
          this.objForm.id_tipo_consulta = String(objResponse.data.id_tipo_consulta);
          this.objForm.fecha_tratamiento = objResponse.data.fecha_tratamiento ? dtFechaTratamiento : null;
          this.objForm.escala_dolor = Number(objResponse.data.escala_dolor);
          this.objForm.strFechaCreacion = objResponse.data.fecha_creacion ? format(new Date(objResponse.data.fecha_creacion), 'dd/MM/yyyy HH:mm:ss') : 'No disponible';
          this.objForm.strUsuarioCreacion = objResponse.data.usuario_creacion ? `${objResponse.data.usuario_creacion.nombre} ${objResponse.data.usuario_creacion.apellido_paterno}` : 'No disponible';
          this.objForm.strFechaActualizacion = objResponse.data.fecha_actualizacion ? format(new Date(objResponse.data.fecha_actualizacion), 'dd/MM/yyyy HH:mm:ss') : 'No disponible';
          this.objForm.strUsuarioActualizacion = objResponse.data.usuario_actualizacion ? `${objResponse.data.usuario_actualizacion.nombre} ${objResponse.data.usuario_actualizacion.apellido_paterno}` : 'No disponible';
          this.objForm.bFirma = objResponse.data.firma ? true : false;
          this.objForm.strImage = objResponse.data.firma ? objResponse.data.firma : '';
        } catch (error) {
          console.error(`Error al obtener consulta: `, error);
        }
      }
    },
    async handlePaciente(){
      try{
        if(this.bEdit) return;
        const objResponse = await axios.get(`/pacientes/${this.id}/info-personal`);
        this.objForm.id_paciente = objResponse.data.id_paciente;
        this.objForm.id_historia_clinica = this.parentId;
        this.strTitle = `${objResponse.data.nombre ?? ''} ${objResponse.data.apellido_paterno ?? ''} ${objResponse.data.apellido_materno ?? ''}`;
      }catch(e){
        console.log(e);
      }
    },
    async handleCatalogos() {
      this.bSpinner = true;
      try {
        const objResponse = await axios.get('/catalogos/consultas');
        this.updateFieldOptions('id_tratamiento', 'nombre', objResponse.data.tratamientos);
        this.updateFieldOptions('id_tipo_consulta', 'nombre', objResponse.data.tiposConsultas);
      } catch (error) {
        console.error('Error al obtener datos relacionados:', error);
      }
      this.bSpinner = false;
    },
    updateFieldOptions(strFieldIndex, strFieldName, objData) {
      const field = this.lstFields.find((field) => field.name === strFieldIndex);
      if (field && Array.isArray(objData)) {
        field.options = objData.map((item) => ({ id: String(item[strFieldIndex]), text: String(item[strFieldName]) }));
      }
      if (field && !Array.isArray(objData)) {
        field.options = [{ id: String(objData[strFieldIndex]), text: String(objData[strFieldName] )}];
      }
    },
    handleSubmit(objForm){
      this.$router.go(-1);
      this.handleInitForm();
    },
    handleCancel(){
      this.$router.go(-1);
    }
  }
};
</script>
