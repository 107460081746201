import { storage } from '@/firebase/firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

// Función para manejar la carga de la imagen
export async function handleUploadFirma(objFirma, strFirmaBase64) {
  const strFileName = objFirma?.strFileName ?? '';
  const strFolderPath = objFirma?.strFolderPath ?? '';
  if(strFileName === '' || strFolderPath === '') return;

  try {
    const strFirma = strFirmaBase64.replace(/^data:image\/\w+;base64,/, '');
    const objBlob = new Blob([new Uint8Array(atob(strFirma).split('').map(char => char.charCodeAt(0)))], { type: 'image/png' });
    const objFileRef = ref(storage, 'pacientes/' + strFolderPath + '/' + strFileName);

    const objSnapshot = await uploadBytes(objFileRef, objBlob);
    const strUrl = await getDownloadURL(objSnapshot.ref);
    return strUrl;

  } catch (error) {
    console.error('Error al subir la firma a Firebase Storage:', error);
    throw new Error('Error al subir la firma');
  }
}
