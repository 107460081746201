<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb :title="strTitle" :text="strFormTitle" :path="strPath" />
      <physiocloud-component-form
        :bEdit="bEdit"
        :strFormTitle="strFormTitle"
        :strRecordId="strRecordId"
        :strApiRoute="strApiRoute"
        :lstFields="lstFields"
        :objForm="objForm" 
        @submit="handleSubmit" 
        @cancel="handleCancel"
      />
    </div>
  </div>
<delete></delete>
</template>
<script>
import axios from "@/axios";
import { format } from 'date-fns';

const lstActivo = [
  { id: "1", text: "Activo" },
  { id: "0", text: "Inactivo" },
];

const lstValues = [
  { id: "1", text: "Si" },
  { id: "0", text: "No" },
];

const lstFields = [
    { name: "id_motivo_consulta", label: "Motivo consulta", type: 'select', required: true, options: [], strClass: "col-12 col-md-6 col-xl-4"},
    { name: "fecha_padecimiento", label: "Fecha padecimiento", type: "date", required: true, strClass: "col-12 col-md-6 col-xl-4" },
    { name: "escala_dolor", label: "Escala dolor", type: "range", required: false, strClass: "col-12 col-md-6 col-xl-4" },
    { name: "diagnostico", label: "Diagnostico", type: "textarea", required: true, strClass: "col-12 col-md-6 col-xl-4" },
    { name: "fecha_inicio_tratamiento", label: "Fecha inicio tratamiento", type: "date", required: true, strClass: "col-12 col-md-6 col-xl-4" },
    { name: "diagnostico_medico", label: "Diagnostico médico", type: "textarea", required: false, strClass: "col-12 col-md-6 col-xl-4" },
    { name: "cirugia", label: "Cirugía", type: "select", required: false, options: lstValues, strClass: "col-12 col-md-6 col-xl-4"},
    { name: "fecha_cirugia", label: "Fecha cirugía", type: "date", required: false, strClass: "col-12 col-md-6 col-xl-4" },
    { name: "fecha_alta", label: "Fecha alta cirugía", type: "date", required: false, strClass: "col-12 col-md-6 col-xl-4" },
    { name: "firma", label: "Firma", type: 'signature', strClass: "col-md-12 col-xl-12"},
  ];

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    recordId: {
      type: String,
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      bEdit: this.isEdit,
      strTitle: "Paciente",
      strRecordId: this.recordId,
      strPath: `/patient/${this.id}/profile`,
      strFormTitle: "Formulario de Historia Clinica",
      strApiRoute: "/historias-clinicas",
      lstActivo: lstActivo,
      lstFields: lstFields,
      objForm: {},
    };
  },
  async created() {
    this.handleInitForm();
    this.handlePaciente();
    this.handleCatalogos();
    this.handleDataInit();
  },
  methods: {
    handleInitForm(){
      this.objForm = {
        bFirma: false,
        strImage: '',
        id_paciente: this.id,
        id_motivo_consulta: "",
        fecha_padecimiento: format(new Date(), 'yyyy-MM-dd'),
        escala_dolor: "",
        diagnostico: "",
        fecha_inicio_tratamiento: format(new Date(), 'yyyy-MM-dd'),
        diagnostico_medico: "",
        cirugia: "",
        fecha_cirugia: "",
        fecha_alta: "",
      };
    },
    async handleDataInit(){
      if(this.bEdit && this.strRecordId){
        const objResponse = await axios.get(`${this.strApiRoute}/${this.strRecordId}`);
        this.objForm = objResponse.data;
        this.strTitle = `${objResponse.data.paciente?.nombre ?? ''} ${objResponse.data.paciente?.apellido_paterno ?? ''} ${objResponse.data.paciente?.apellido_materno ?? ''}`;
        this.objForm.id_motivo_consulta = String(objResponse.data.id_motivo_consulta);
        this.objForm.fecha_padecimiento = objResponse.data.fecha_padecimiento ? objResponse.data.fecha_padecimiento.split("T")[0] : null;
        this.objForm.fecha_inicio_tratamiento = objResponse.data.fecha_inicio_tratamiento ? objResponse.data.fecha_inicio_tratamiento.split("T")[0] : null;
        this.objForm.fecha_cirugia = objResponse.data.fecha_cirugia ? objResponse.data.fecha_cirugia.split("T")[0] : null;
        this.objForm.fecha_alta = objResponse.data.fecha_alta ? objResponse.data.fecha_alta.split("T")[0] : null;
        this.objForm.cirugia = String(objResponse.data.cirugia);
        this.objForm.strFechaCreacion = objResponse.data.fecha_creacion ? format(new Date(objResponse.data.fecha_creacion), 'dd/MM/yyyy HH:mm:ss') : 'No disponible';
        this.objForm.strUsuarioCreacion = objResponse.data.usuario_creacion ? `${objResponse.data.usuario_creacion.nombre} ${objResponse.data.usuario_creacion.apellido_paterno}` : 'No disponible';
        this.objForm.strFechaActualizacion = objResponse.data.fecha_actualizacion ? format(new Date(objResponse.data.fecha_actualizacion), 'dd/MM/yyyy HH:mm:ss') : 'No disponible';
        this.objForm.strUsuarioActualizacion = objResponse.data.usuario_actualizacion ? `${objResponse.data.usuario_actualizacion.nombre} ${objResponse.data.usuario_actualizacion.apellido_paterno}` : 'No disponible';
        this.objForm.bFirma = objResponse.data.firma ? true : false;
        this.objForm.strImage = objResponse.data.firma ? objResponse.data.firma : '';
      }
    },
    async handlePaciente(){
      try{
        if(this.bEdit) return;
        const objResponse = await axios.get(`/pacientes/${this.id}/info-personal`);
        this.objForm.id_paciente = objResponse.data.id_paciente;
        this.strTitle = `${objResponse.data.nombre ?? ''} ${objResponse.data.apellido_paterno ?? ''} ${objResponse.data.apellido_materno ?? ''}`;
      }catch(e){
        console.log(e);
      }
    },
    async handleCatalogos(){
      try{
        const objResponse = await axios.get("/motivos-consulta");
        this.lstFields.find(objField => objField.name === 'id_motivo_consulta' ).options = objResponse.data.map(objItem => ({
          id: String(objItem.id_motivo_consulta),
          text: String(objItem.nombre),
        }));
      }catch(e){
        console.log(e);
      }
    },
    handleSubmit(objForm){
      this.$router.go(-1);
      this.handleInitForm();
    },
    handleCancel(){
      this.$router.go(-1);
    }
  }
};
</script>
