import { reactive, ref, computed, watch, onMounted, onUnmounted, nextTick } from "vue";
import dayjs from 'dayjs';
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import esLocale from '@fullcalendar/core/locales/es';

const objCalendarOptions = reactive({
  locale: esLocale,
  now: dayjs().toISOString(),
  plugins: [
    dayGridPlugin,
    timeGridPlugin,
    interactionPlugin,
  ],
  initialView: "timeGridWeek",
  showNonCurrentDates: false,
  headerToolbar: {
    left: "prev,next,today",
    center: "title",
    right: "timeGridWeek,timeGridDay",
  },
  buttonText: {
    today: "Hoy",
    month: "Mes",
    week: "Semana",
    day: "Día",
  },
  businessHours: [
    {
      daysOfWeek: [0, 1, 2, 3, 4, 5, 6],
      startTime: "08:00",
      endTime: "20:00",
    },
  ],
  //hiddenDays: [0],
  selectConstraint: "businessHours",
  eventConstraint: "businessHours",
  slotLabelFormat: {
    hour: "numeric",
    minute: "2-digit",
    omitZeroMinute: false,
    meridiem: "short",
  },
  slotMinTime: "08:00:00",
  events: [],
  selectMirror: true,
  dayMaxEvents: true,
  editable: true,
  droppable: true,
  allDaySlot: false,
  eventDurationEditable: false,
  timeZone: 'America/Mexico_City',
});

export default objCalendarOptions;