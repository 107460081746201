<template>
  <div class="row">
    <div class="col-sm-12">
      <div class="card">
        <div class="card-body">
          <form @submit.prevent="submitForm">
            <div class="row">
              <div class="col-12">
                <div class="form-heading">
                  <h4>{{ strFormTitle }}</h4>
                </div>
              </div>

              <Spinner ref="Spinner" v-if="bSpinner"/>

              <template v-for="(field, index) in lstFields" :key="index" v-if="bSpinner == false" >
                <div :class="field.strClass">
                  <div class="input-block local-forms">
                    <template v-if="field.type === 'range'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                      <div class="card-body roundskin-slider">
                        <Slider v-model="objFormData[field.name]" class="roundskin" :min="0" :max="10" @change="handleRange(field.name, $event)" />
                      </div>
                    </template>
                    <template v-if="field.type === 'text' || field.type === 'date'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                      <input
                        class="form-control"
                        :type="field.type"
                        v-model="objFormData[field.name]"
                        :placeholder="field.placeholder"
                        :required="field.required"
                        :disabled="field.disabled"
                      />
                    </template>
                    <template v-if="field.type === 'textarea'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                      <b-form-textarea
                        id="textarea"
                        v-model="objFormData[field.name]"
                        rows="5"
                        max-rows="6"
                        :required="field.required"
                        placeholder="Ingresa el texto aqui">
                      </b-form-textarea>
                    </template>
                    <template v-if="field.type === 'select'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                      <vue-select
                        :options="field.options || []"
                        v-model="objFormData[field.name]"
                        placeholder="Selecciona una opción"
                        @select="handleSelectField(field.name, $event)"
                        :disabled="field.disabled"
                        :required="field.required"
                      />
                    </template>
                    <template v-if="field.type === 'signature'">
                      <label>{{ field.label }} <span class="login-danger" v-if="field.required">*</span></label>
                        <template v-if="objFormData.bFirma == false">
                          <Vue3Signature :ref="el => lstSignatureRefs[index] = el" :sigOption="objSignatureConfig.options" 
                          :w="'100%'" :h="'400px'" :disabled="objSignatureConfig.disabled" />
                          <b-button @click="handleClearSignature(index)" :disabled="objSignatureConfig.disabled" variant="secondary me-1">Limpiar firma</b-button>
                        </template>
                        <img :src="objFormData.strImage" width="70%" alt="Imagen en base64" v-if="objFormData.bFirma" />
                    </template>
                  </div>
                </div>
              </template>
              <div class="col-12">
                <div>
                  <b-button type="submit" variant="primary me-1" v-if="!bGuardar && bSaveButton" >Guardar Registro</b-button>
                  <b-button variant="primary me-1" v-if="bGuardar" >
                    <b-spinner class="spinner-border-sm me-1"></b-spinner>
                    Guardando...
                  </b-button>
                  <b-button @click="handleCancel" variant="secondary me-1" v-if="bCancelButton">Cancelar</b-button>
                </div>
              </div>
            </div>
          </form>
        </div>
        <!--div class="row" v-if="isEdit">
          <div class="col-sm-6 col-12">
            <small class="text-muted">Creación: {{ objForm.strUsuarioCreacion }}, {{ objForm.strFechaCreacion }}</small>
          </div>
          <div class="col-sm-6 col-12 text-end">
            <small class="text-muted">Actualización: {{ objForm.strUsuarioActualizacion }}, {{ objForm.strFechaActualizacion }}</small>
          </div>
        </div-->
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import axios from "@/axios";
import Spinner from "@/components/Spinner.vue";
import Slider from "@vueform/slider";
import Vue3Signature from "vue3-signature";
import { handleUploadFirma } from '@/firebase/PhysioCloudUploadFile';

const lstSignatureRefs = ref([]);
const objSignatureConfig = {
  options: {
    canvasWidth: 800,
    canvasHeight: 400,
    backgroundColor: "rgb(249, 249, 249)",
    penColor: "rgb(0, 0, 0)",
    velocityFilterWeight: 0.7,
    minWidth: 0.5,
    maxWidth: 2.5,
    throttle: 16,
    minPointDistance: 3,
  },
  disabled: false,
};

export default {
  props: {
    bEdit: { type: Boolean, default: false },
    bSaveButton: { type: Boolean, default: true },
    bCancelButton: { type: Boolean, default: true },
    strFormTitle: { type: String, default: "Formulario de Detalles" },
    strApiRoute: { type: String, default: "/api/" },
    strRecordId: { type: String, default: null },
    lstFields: { type: Array, required: true },
    objForm: { type: [Object, Array, String, Number], required: true },
    modelValue: { type: [Object, Array, String, Number], default: '' },
  },
  data() {
    return {
      bSpinner: false,
      bGuardar: false,
      objFormData: this.objForm,
      objSignatureConfig,
      lstSignatureRefs,
    };
  },
  watch: {
    bEdit(bNewValue){
      this.bEdit = bNewValue;
    },
    objForm(objNewValue) {
      this.objFormData = { ...objNewValue};
    },
  },
  emits: ['submit', 'cancel', 'select', 'update:modelValue'],
  components: {
    Slider,
    Vue3Signature,
    Spinner,
  },
  methods: {
    // Actualiza los valores del formulario
    updateValue(fieldName) {
      this.$emit('update:modelValue', this.objFormData);
    },
    handleRange(strFieldName, strValue) {
      this.objForm[strFieldName] = strValue;
    },
    handleSelectField(strFieldName, strValue) {
      if (typeof strValue !== "object" || Object.keys(strValue).length === 0) return;
      if (typeof strValue.id === "number") {
        strValue = String(strValue.id);
      } else if (typeof strValue.text === "number") {
        strValue = String(strValue.text);
      } else {
        strValue = strValue.id || strValue.text || "";
      }

      this.objFormData[strFieldName] = strValue;
      this.$emit('select', strFieldName, strValue);
    },
    handleClearSignature(index) {
      this.lstSignatureRefs[index].clear();
    },

    // Llamado al hacer submit del formulario
    async submitForm() {
      if(!this.handleValidateForm()) return;
      this.bGuardar = true;
      try {
        let objResponse = {};
        await this.handleFirma();
        if (this.bEdit) objResponse = await axios.put(`${this.strApiRoute}/${this.strRecordId}`, this.objFormData);
        if(!this.bEdit) objResponse = await axios.post(this.strApiRoute, this.objFormData);

        this.toast('success', true, 'Información guardada correctamente');
        this.$emit('submit', objResponse.data);
        if (!this.bEdit) this.handleResetForm();
      } catch (error) {
        console.error("Error al enviar el formulario", error);
        this.toast('error', true, 'Error al guardar la información');
      } finally {
        this.bGuardar = false;
      }
    },
    handleValidateForm() {
      const lstRequiredFields = this.lstFields.filter((field) => field.required);
      const lstInvalidFields = lstRequiredFields.filter((field) => !this.objFormData[field.name]);
      if (lstInvalidFields.length > 0) {
        this.toast('error', true, 'Favor de llenar todos los campos requeridos');
        return false;
      }
      return true;
    },
    async handleFirma(){
      const bFirma = this.lstFields.some(objField => objField.type === 'signature');
        if(bFirma){
          const strFolderPath = `PX-${this.objFormData.id_paciente}/firmas/${this.strApiRoute}`;
          const objFirma = {
            strFolderPath : strFolderPath,
            strFileName : `PX-${this.objFormData.id_paciente}_${Date.now()}.png`,
          }

          const strFirmaBase64 = this.lstSignatureRefs.filter(objRef => objRef !== null && !objRef.isEmpty())
          .map(objRef => objRef.save('image/png', 1, 0.5))[0];
          if(strFirmaBase64 && strFirmaBase64.trim() !== ''){
            const strUrl = await handleUploadFirma(objFirma, strFirmaBase64);
            console.log('Firma subida correctamente', strUrl);
            this.objFormData.firma = String(strUrl);
          }
        }
    },
    handleResetForm() {
      this.objFormData = this.objForm;
    },
    handleCancel() {
      this.$emit('cancel');
    },

    toast(type = "default", dismissible = true, message = "") {
      const options = {
        dismissible,
        onClick: this.onClick,
        duration: 3000,
      };
      typeof type === "string" && (options.type = type);
      typeof options.maxToasts === "string" &&
        (options.maxToasts = parseInt(options.maxToasts));

      this.$toast.show(message, {
        ...options,
      });
    },
  },
};
</script>
<style>
  .select2-container--default .select2-dropdown {
    z-index: 9999 !important; /* Asegura que el dropdown esté por encima de otros elementos */
  }
  .roundskin .slider-connect {
    background: var(--slider-connect-bg, #2e37a4) !important;
    cursor: pointer;
    height: 4px;
  }
  .roundskin .slider-base,
  .slider-connects {
    height: 4px;
    position: relative;
    width: 100%;
    border-radius: 4px;
  }
  .roundskin .slider-tooltip {
    background: var(--slider-tooltip-bg, #006cfa) !important;
    border: 1px solid var(--slider-tooltip-bg, #006cfa) !important;
    border-radius: var(--slider-tooltip-radius, 5px);
    color: var(--slider-tooltip-color, #fff);
    display: block;
    font-size: var(--slider-tooltip-font-size, 0.875rem);
    font-weight: var(--slider-tooltip-font-weight, 600);
    line-height: var(--slider-tooltip-line-height, 1.25rem);
    min-width: var(--slider-tooltip-min-width, 20px);
    padding: var(--slider-tooltip-py, 2px) var(--slider-tooltip-px, 6px);
    position: absolute;
    text-align: center;
    white-space: nowrap;
  }
  .roundskin-slider .slider-horizontal .slider-handle {
    top: calc(
      (var(--slider-handle-height, 30px) - var(--slider-height, 6px)) / 2 * -1 + 2px
    );
    width: 24px;
    height: 24px;
    position: absolute;
    display: block;
    border: 4px solid #006cfa;
    background-color: white;
    border-radius: 24px;
    box-shadow: none;
  }
</style>
<style src="@vueform/slider/themes/default.css"></style>
