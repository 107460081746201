<template>
  <!-- Page Wrapper -->
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb title="Tickets" text="Listado de Tickets" path="/ticket/list" />
    
      <div class="page-header invoices-page-header">
        <div class="row align-items-center">
          <div class="col">
            <div class="col-12 col-md-6 col-xl-5">
              <div class="input-block ">
                <label>Filtro Estatus</label>
                <vue-select
                  :options="lstEstatusTicket"
                  placeholder="Selecciona estatus"
                  @select="handleSelect"
                  v-model="strFiltroEstatus"/>
              </div>
            </div>
          </div>
          <div class="col-auto">
            <div class="invoices-create-btn">
              <a
                href="javascript:;"
                data-bs-toggle="modal"
                data-bs-target="#new_ticket_form"
                class="btn save-invoice-btn">
                Nuevo ticket
              </a>
            </div>
          </div>
        </div>
      </div>
      <TicketReport />
      <physiocloud-component-list
        :bLoading="bLoading"
        :bSelectRow="bSelectRow"
        :bAddButton="false"
        :strTitleList="strTitleList"
        :strRoute="strRoute"
        :strPrimaryKey="strPrimaryKey"
        :objPagination="objPagination"
        :lstColumns="lstColumns"
        :lstData="lstData"
        @refresh="handleDataInit"
      />
      <TicketFormModal @submit="handleSubmit"/>
    </div>
  </div>
<!-- /Page Wrapper -->

</template>
<script>
import axios from "@/axios";
import { format } from 'date-fns';
import TicketReport from "./TicketReport.vue";
import TicketFormModal from "./TicketFormModal.vue";

const lstEstatusTicket = [
  { id: "1", text: 'Todos' },
];

const lstColumns = [
  { strTitle: "Ticket", strDataIndex: "strTicket", strKey: "strTicket", bFiler: true, 
    bUrl: true, strUrl: '/ticket/edit', strUrlKey: 'id_ticket' },
  { strTitle: "Paciente", strDataIndex: "strPaciente", strKey: "strPaciente", bFiler: true },
  { strTitle: "Total", strDataIndex: "strTotal", strKey: "strTotal", bFiler: true },
  { strTitle: "Forma de Pago", strDataIndex: "strFormaPago", strKey: "strFormaPago", bFiler: true },
  { strTitle: "Estatus", strDataIndex: "strEstatus", strKey: "strEstatus", bFiler: true },
  { strTitle: "Fecha", strDataIndex: "strFecha", strKey: "strFecha", bFiler: true },
  { strTitle: "", strDataIndex: "action", strKey: "action", sorter: false, strClass: "text-end" },
];

export default {
  data() {
    return {
      bLoading: false,
      bSelectRow: false,
      strTitleList: "Tickets",
      strRoute: '/ticket',
      strPrimaryKey: 'id_ticket',
      objPagination: { pageSize: 15 },
      lstEstatusTicket,
      lstColumns: lstColumns,
      lstData: [],
      strFiltroEstatus: '',
      strApiRoute: '/tickets',
    };
  },
  components: {
    TicketReport,
    TicketFormModal
  },
  async created() {
    await this.handleDataInit();
    this.handleEstatus();
  },
  methods: {
    async handleDataInit(strRouteApi = '/tickets') {
      try{
        this.bLoading = true;
        const objResponse = await axios.get(`${strRouteApi}`);
        this.lstData = objResponse.data.map((objResponse) => ({
          ...objResponse,
          strTicket: `TK-#${objResponse.id_ticket}`,
          strPaciente: objResponse.paciente ? 
            `${objResponse.paciente.nombre} 
            ${objResponse.paciente.apellido_paterno ?? ''} 
            ${objResponse.paciente.apellido_materno ?? ''}` : '',
          strTotal: `$${objResponse.total ?? '0.00'}`,
          strFormaPago: `${objResponse.forma_pago?.nombre ?? ''}`,
          strEstatus: `${objResponse.estatus_ticket?.nombre ?? ''}`,
          strFecha: objResponse.fecha ? format(new Date(objResponse.fecha), 'dd/MM/yyyy HH:mm:ss') : '',
        }));
      } catch (error) {
        console.error(error);
      } finally {
        this.bLoading = false;
      }
    },
    async handleEstatus(){
      try{
        const objResponse = await axios.get(`/estatus-tickets`);
        this.lstEstatusTicket = objResponse.data.map((objResponse) => ({
          id: String(objResponse.id_estatus_ticket),
          text: String(objResponse.nombre),
        }));
        this.lstEstatusTicket.unshift({ id: "0", text: 'Todos' });
        this.strFiltroEstatus = '0';
      }catch (error) {
        console.error(error);
      }

    },
    handleSelect(objRecord) {
      const strRouteApi = objRecord.id === '0' ? '/tickets' : `/tickets?estatus_ticket=${objRecord.id}`;
      this.handleDataInit(strRouteApi);
    },
    handleSubmit(objForm){
      console.log(objForm);
      this.$router.push({ name: "/ticket/edit", params: { recordId: objForm.id_ticket } });
    },
  }
};
</script>
