<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb :title="strTitle" :text="strFormTitle" :path="strPath" />
      <physiocloud-component-form
        :bEdit="bEdit"
        :strFormTitle="strFormTitle"
        :strRecordId="strRecordId"
        :strApiRoute="strApiRoute"
        :lstFields="lstFields"
        :objForm="objForm" 
        @submit="handleSubmit" 
        @cancel="handleCancel"
      />
    </div>
  </div>
<delete></delete>
</template>
<script>
import axios from "@/axios";
import { format } from 'date-fns';

const lstFields = [
  { name: "id_extremidad", label: "Extremidad", type: 'select', required: true, options: [], strClass: "col-12 col-md-6 col-xl-4"},
  { name: "id_cuerpo_humano", label: "Parte Cuerpo", type: 'select', required: true, options: [], strClass: "col-12 col-md-6 col-xl-4"},
  { name: "id_prueba_fisica", label: "Prueba ejecutada", type: 'select', required: true, options: [], strClass: "col-12 col-md-6 col-xl-4"},
  { name: "descripcion", label: "Comentarios", type: 'textarea', required: true, strClass: "col-12 col-md-6 col-xl-4"},
];

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
    recordId: {
      type: String,
      default: null,
    },
    parentId1: {
      type: String,
      default: null,
    },
    parentId2: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      bEdit: this.isEdit,
      strRecordId: this.recordId,
      strTitle: "Paciente",
      strFormTitle: "Formulario de Prueba Paciente",
      strPath: ``,
      strApiRoute: "/pruebas-pacientes",
      lstFields: lstFields,
      objForm: {},
    };
  },
  async created() {
    this.handleCatalogos();
    this.handlePaciente();
    this.handleInitForm();
    await this.handleDataInit();
    this.strPath = `/patient/${this.id}/medical-history/${this.parentId1}/consultation/${this.parentId2}/detail`;
  },
  methods: {
    handleInitForm(){
      this.objForm = {
        nombre: "",
        codigo: "",
        descripcion: "",
        activo: "1",
      };
    },
    async handleDataInit(){
      if(this.bEdit && this.strRecordId){
        this.bSpinner = true;
        try {
          const objResponse = await axios.get(`/pruebas-pacientes/${this.recordId}`);
          this.objForm = objResponse.data;
          const strPaciente = `${objResponse.data.paciente.nombre ?? ''} ${objResponse.data.paciente.apellido_paterno ?? ''} ${objResponse.data.paciente.apellido_materno ?? ''}`;
          this.strTitle = `Consulta #${objResponse.data.id_consulta} (${strPaciente})`;

          this.objForm.id_extremidad = String(objResponse.data.id_extremidad);
          this.objForm.id_cuerpo_humano = String(objResponse.data.id_cuerpo_humano);
          this.objForm.id_prueba_fisica = String(objResponse.data.id_prueba_fisica);
          this.objForm.strFechaCreacion = objResponse.data.fecha_creacion ? format(new Date(objResponse.data.fecha_creacion), 'dd/MM/yyyy HH:mm:ss') : 'No disponible';
          this.objForm.strUsuarioCreacion = objResponse.data.usuario_creacion ? `${objResponse.data.usuario_creacion.nombre} ${objResponse.data.usuario_creacion.apellido_paterno}` : 'No disponible';
          this.objForm.strFechaActualizacion = objResponse.data.fecha_actualizacion ? format(new Date(objResponse.data.fecha_actualizacion), 'dd/MM/yyyy HH:mm:ss') : 'No disponible';
          this.objForm.strUsuarioActualizacion = objResponse.data.usuario_actualizacion ? `${objResponse.data.usuario_actualizacion.nombre} ${objResponse.data.usuario_actualizacion.apellido_paterno}` : 'No disponible';   
        } catch (error) {
          console.error(`Error al obtener consulta: `, error);
        }
        this.bSpinner = false;
      }
    },
    async handlePaciente(){
      try{
        if(this.bEdit) return;
        const objResponse = await axios.get(`/pacientes/${this.id}/info-personal`);
        this.objForm.id_paciente = objResponse.data.id_paciente;
        this.objForm.id_historia_clinica = this.parentId1;
        this.objForm.id_consulta = this.parentId2;
        const strPaciente = `${objResponse.data.nombre ?? ''} ${objResponse.data.apellido_paterno ?? ''} ${objResponse.data.apellido_materno ?? ''}`;
        this.strTitle = `Consulta #${this.parentId2} (${strPaciente})`;
      }catch(e){
        console.log(e);
      }
    },
    async handleCatalogos() {
      this.bSpinner = true;
      try {
          const objResponse = await axios.get('/catalogos/pruebas-pacientes');
          this.updateFieldOptions('id_extremidad', 'nombre', objResponse.data.extremidades);
          this.updateFieldOptions('id_cuerpo_humano', 'nombre', objResponse.data.cuerposHumanos);
          this.updateFieldOptions('id_prueba_fisica', 'nombre', objResponse.data.pruebasFisicas);
      } catch (error) {
          console.error('Error al obtener datos relacionados:', error);
      }
      this.bSpinner = false;
    },
    updateFieldOptions(strFieldIndex, strFieldName, objData) {
        const field = this.lstFields.find((field) => field.name === strFieldIndex);
        if (field && Array.isArray(objData)) {
            field.options = objData.map((item) => ({ id: String(item[strFieldIndex]), text: String(item[strFieldName]) }));
        }
        if (field && !Array.isArray(objData)) {
            field.options = [{ id: String(objData[strFieldIndex]), text: String(objData[strFieldName] )}];
        }
    },
    handleSubmit(objForm){
      this.$router.go(-1);
      this.handleInitForm();
    },
    handleCancel(){
      this.$router.go(-1);
    }
  }
};
</script>
