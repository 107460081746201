<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="strTitle" :text="strSubTitle" :path="strPath" />
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="row">
            <div class="col-lg-4">
              <div class="doctor-personals-grp">
                <div class="card">
                  <div class="card-body">
                    <div class="heading-detail">
                      <h4 class="mb-3">Información medica</h4>
                      <p>
                        <strong>Diagnostico</strong>: {{ objHistoriaClinica.diagnostico }}
                      </p>
                    </div>
                    <div class="about-me-list">
                      <ul class="list-space">
                        <li>
                          <h4>Paciente</h4>
                          <span>{{ objPaciente.strNombre }}</span>
                        </li>
                        <li>
                          <h4>Genero</h4>
                          <span>{{ objPaciente.strGenero }}</span>
                        </li>
                        <!--li>
                          <h4>Fecha Tratamiento</h4>
                          <span>30+</span>
                        </li-->
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!--div class="doctor-personals-grp">
                <div class="card">
                  <div class="card-body">
                    <div class="heading-detail">
                      <h4>Skills:</h4>
                    </div>
                    <div class="skill-blk">
                      <div class="skill-statistics">
                        <div class="skills-head">
                          <h5>Heart Beat</h5>
                          <p>45%</p>
                        </div>
                        <div class="progress mb-0">
                          <div
                            class="progress-bar bg-operations"
                            role="progressbar"
                            style="width: 45%"
                            aria-valuenow="45"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="skill-statistics">
                        <div class="skills-head">
                          <h5>Haemoglobin</h5>
                          <p>85%</p>
                        </div>
                        <div class="progress mb-0">
                          <div
                            class="progress-bar bg-haemoglobin"
                            role="progressbar"
                            style="width: 85%"
                            aria-valuenow="85"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="skill-statistics">
                        <div class="skills-head">
                          <h5>Blood Pressure</h5>
                          <p>65%</p>
                        </div>
                        <div class="progress mb-0">
                          <div
                            class="progress-bar bg-statistics"
                            role="progressbar"
                            style="width: 65%"
                            aria-valuenow="65"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                      <div class="skill-statistics">
                        <div class="skills-head">
                          <h5>Sugar</h5>
                          <p>90%</p>
                        </div>
                        <div class="progress mb-0">
                          <div
                            class="progress-bar bg-visit"
                            role="progressbar"
                            style="width: 90%"
                            aria-valuenow="90"
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div-->
            </div>
            <div class="col-lg-8">
              <div class="doctor-personals-grp">
                <div class="card">
                  <div class="card-body">
                    <div class="tab-content-set">
                      <ul class="nav">
                        <li>
                          <router-link to="#" class="active" @click.prevent>
                            <span class="set-about-icon me-2">
                              <img src="@/assets/img/icons/menu-icon-02.svg" alt="" />
                            </span>Consulta</router-link>
                        </li>
                      </ul>
                    </div>
                    <div class="personal-list-out">
                      <div class="row">
                        <div class="col-xl-3 col-md-6">
                          <div class="detail-personal">
                            <h2>Historia Clinica</h2>
                            <h3>MEDHIST-#{{ parentId }}</h3>
                          </div>
                        </div>
                        <div class="col-xl-3 col-md-6">
                          <div class="detail-personal">
                            <h2>Tratamiento</h2>
                            <h3>{{ objConsulta.strTratamiento }}</h3>
                          </div>
                        </div>
                        <div class="col-xl-3 col-md-6">
                          <div class="detail-personal">
                            <h2>Escala del dolor</h2>
                            <h3>{{ objConsulta.escala_dolor }}</h3>
                          </div>
                        </div>
                        <div class="col-xl-3 col-md-6">
                          <div class="detail-personal">
                            <h2>Tipo Consulta</h2>
                            <h3>{{ objConsulta.strTipoConsulta }}</h3>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="hello-park">
                      <p>
                        <strong>Comentarios:</strong> {{ objConsulta.comentarios }}
                      </p>
                    </div>
                  </div>
                </div>
                <div class="card">
                  <PatientTestList :id="id" :recordId="recordId" :parentId="parentId" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from '@/axios';
import PatientTestList from '@/views/pages/patient-test/PatientTestList.vue';

export default {
  props: {
    isEdit: { type: Boolean, default: false },
    id: { type: String, default: null },
    parentId: { type: String, default: null },
    recordId: { type: String, default: null },
  },
  data() {
    return {
      strTitle: "Historia Clinica",
      strSubTitle: "Detalles de la consulta",
      strPath: `/patient/${this.id}/medical-history/${this.parentId}/consultation/list`,
      objPaciente: {},
      objConsulta: {},
      objHistoriaClinica: {},
    };
  },
  components: {
    PatientTestList,
  },
  async created() {
    this.handleDataInit();
  },
  methods: {
    async handleDataInit(){
      try {
        const objResponse = await axios.get(`/consultas/${this.recordId}/detalle`);
        this.strTitle = `Historia Clínica #${objResponse.data.id_historia_clinica} (${objResponse.data.paciente?.nombre ?? ''} ${objResponse.data.paciente?.apellido_paterno ?? ''} ${objResponse.data.paciente?.apellido_materno ?? ''})`;
        this.objPaciente.strNombre = `${objResponse.data.paciente?.nombre ?? ''} ${objResponse.data.paciente?.apellido_paterno ?? ''} ${objResponse.data.paciente?.apellido_materno ?? ''}`;
        this.objPaciente.strGenero = objResponse.data.paciente?.genero?.nombre ?? 'No disponible';
        this.objHistoriaClinica.diagnostico = objResponse.data.diagnostico ?? 'No disponible';
        this.objConsulta.id_tratamiento = String(objResponse.data.id_tratamiento);
        this.objConsulta.strTratamiento = objResponse.data.tratamiento?.nombre ?? '';
        this.objConsulta.escala_dolor = objResponse.data.escala_dolor ?? 'No disponible';
        this.objConsulta.id_tipo_consulta = String(objResponse.data.id_tipo_consulta);
        this.objConsulta.strTipoConsulta = objResponse.data.tipo_consulta?.nombre ?? 'No disponible';
        this.objConsulta.comentarios = objResponse.data.comentarios ?? 'No disponible';
      } catch (error) {
        console.error(`Error al obtener consulta: `, error);
      }
    },
  },
};
</script>