<template>
  <physiocloud-component-list
    :bLoading="bLoading"
    :bAddButton="false"
    :strTitleList="'Paises'"
    :strRoute="'/country'"
    :strPrimaryKey="'id_pais'"
    :objPagination="{ pageSize: 15 }"
    :lstColumns="lstColumns"
    :lstData="lstData"
    @refresh="handleDataInit"
  />
<delete></delete>
</template>
<script>
import axios from "@/axios";
import { format } from 'date-fns';

const lstColumns = [
{ strTitle: "Nombre", strDataIndex: "nombre", strKey: "nombre", bFilter: true },
{ strTitle: "Codigo", strDataIndex: "codigo", strKey: "codigo", bFilter: true },
{ strTitle: "Activo", strDataIndex: "strActivo", strKey: "strActivo"},
{ strTitle: "Fecha Creación", strDataIndex: "strFechaCreacion", strKey: "strFechaCreacion"},
{ strTitle: "", strDataIndex: "action", strKey: "action", sorter: false, strClass: "text-end"},
];

export default {
  data() {
    return {
      bLoading: false,
      lstColumns: lstColumns,
      lstData: [],
    };
  },
  async created() {
    this.handleDataInit();
  },
  methods: {
    async handleDataInit() {
      try{
        this.bLoading = true;
        const objResponse = await axios.get(`/paises`);
        this.lstData = objResponse.data.map((objResponse) => ({
          ...objResponse,
          strActivo: objResponse.activo ? "Activo" : "Inactivo",
          strFechaCreacion: format(new Date(objResponse.fecha_creacion), 'dd/MM/yyyy HH:mm:ss'),
        }));
      } catch (error) {
        console.error(error);
      } finally {
        this.bLoading = false;
      }
    },
    handleSelect(objRecord) {
      console.log(objRecord);
    },
  }
};
</script>
