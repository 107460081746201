<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Selector para elegir entre "Historias Clínicas" o "Consultas" -->
      <vue-select
        :options="lstOptions"
        v-model="strSelected"
        placeholder="Selecciona una opción"
        @select="handleSelectField"
        :disabled="isLoading"
      />
      
      <!-- Botón para subir las firmas -->
      <b-button @click="uploadAllSignatures" :disabled="isLoading" variant="primary me-1">
        {{ isLoading ? "Subiendo..." : "Subir Firmas" }}
      </b-button>

      <!-- Mensaje de estado -->
      <p v-if="statusMessage" :class="statusClass">{{ statusMessage }}</p>
    </div>
  </div>
</template>

<script>
import axios from "@/axios";
import { handleUploadFirma } from "@/firebase/PhysioCloudUploadFile";

export default {
  data() {
    return {
      isLoading: false,
      statusMessage: "",
      statusClass: "",
      strSelected: 'historias-clinicas',
      lstOptions: [
        { text: "Historias Clínicas", id: "historias-clinicas" },
        { text: "Consultas", id: "consultas" }
      ],
    };
  },
  methods: {
    // Función que maneja la subida de todas las firmas según la opción seleccionada
    async uploadAllSignatures() {
      if (!this.strSelected) {
        this.statusMessage = "Por favor, selecciona una opción.";
        this.statusClass = "error";
        return;
      }

      this.isLoading = true;
      this.statusMessage = "";
      this.statusClass = "";

      try {
        let strEndpoint = "";
        let strPrimaryKey = "";
        
        // Determina el endpoint según la opción seleccionada
        if (this.strSelected === "historias-clinicas") {
          strEndpoint = "/historias-clinicas";
          strPrimaryKey = "id_historia_clinica";
        } else if (this.strSelected === "consultas") {
          strEndpoint = "/consultas";
          strPrimaryKey = "id_consulta";
        }

        // Obtener los registros desde la API según el endpoint
        const response = await axios.get(strEndpoint);
        const lstData = response.data;

        for (let objData of lstData) {
          const firmaBase64 = objData.firma;

          const strFolderPath = `PX-${objData.id_paciente}/firmas/${strEndpoint}`;
          const objFirma = {
            strFolderPath : strFolderPath,
            strFileName : `PX-${objData.id_paciente}_${Date.now()}.png`,
          }

          // Validar que la firma esté en base64 y no tenga una URL ya
          if (firmaBase64 && firmaBase64.startsWith("data:image") && !firmaBase64.startsWith("http")) {
            console.log(`Procesando registro ID ${objData[strPrimaryKey]} con firma:`, objData.firma);

            // Subir la firma a Firebase Storage
            const downloadURL = await handleUploadFirma(objFirma, firmaBase64);
            objData.firma = downloadURL;
            // Actualizar la base de datos con la URL
            await axios.put(`${strEndpoint}/${objData[strPrimaryKey]}`, objData);

            console.log(`Firma subida y URL actualizada para el registro ID ${objData[strPrimaryKey]}`);
          } else {
            console.log(`Firma no válida o ya existe para el registro ID ${objData[strPrimaryKey]}`);
          }
        }

        this.statusMessage = "¡Firmas subidas correctamente!";
        this.statusClass = "success";

      } catch (error) {
        console.error("Error al procesar los registros:", error);
        this.statusMessage = "Error al subir las firmas.";
        this.statusClass = "error";
      } finally {
        this.isLoading = false;
      }
    },

    // Manejador de la selección del campo
    handleSelectField(selected) {
      console.log("Opción seleccionada:", selected);
    }
  },
};
</script>