<template>
  <div class="page-wrapper">
    <div class="content">
      <!-- Page Header -->
      <breadcrumb :title="strTitle" :text="strSubTitle" path="/patient" />
      <!-- /Page Header -->
      <div class="row">
        <div class="col-sm-12">
          <div class="card">
            <PatientProfileInfo :id="id" />
          </div>
          <PatientProfileRelatedInfo :id="id" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import PatientProfileInfo from './PatientProfileInfo.vue';
import PatientProfileRelatedInfo from './PatientProfileRelatedInfo.vue';

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
  },
  components: {
    PatientProfileRelatedInfo,
    PatientProfileInfo
  },
  data() {
    return {
      strTitle: "Pacientes",
      strSubTitle: "Perfil del paciente",
    };
  },
  async created() {

  },
  methods: {

  },
}
</script>
