<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb title="Articulo" :text="strFormTitle" :path="strPath" />
      <physiocloud-component-form
        :bEdit="bEdit"
        :strFormTitle="strFormTitle"
        :strRecordId="strRecordId"
        :strApiRoute="strApiRoute"
        :lstFields="lstFields"
        :objForm="objForm" 
        @submit="handleSubmit" 
        @cancel="handleCancel"
      />
    </div>
  </div>
<delete></delete>
</template>
<script>
import axios from "@/axios";

const lstActivo = [
  { id: "1", text: "Activo" },
  { id: "0", text: "Inactivo" },
];

const lstFields = [
  { name: "nombre", label: "Nombre", type: 'text', required: true, strClass: "col-12 col-md-6 col-xl-4"},
  { name: "codigo", label: "Codigo", type: 'text', strClass: "col-12 col-md-6 col-xl-4"},
  { name: "descripcion", label: "Descripcion", type: 'text', strClass: "col-12 col-md-6 col-xl-4"},
  { name: "sku", label: "SKU", type: 'text', strClass: "col-12 col-md-6 col-xl-4"},
  { name: "id_categoria_articulo", label: "Categoria Articulo", type: 'select', options: lstActivo, strClass: "col-12 col-md-6 col-xl-4"},
  { name: "activo", label: "Activo", type: 'select', options: lstActivo, strClass: "col-12 col-md-6 col-xl-4"},
];

export default {
  props: {
    isEdit: {
      type: Boolean,
      default: false,
    },
    recordId: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      bEdit: this.isEdit,
      strRecordId: this.recordId,
      strPath: "/settings/pos",
      strFormTitle: "Formulario de Articulo",
      strApiRoute: "/articulos",
      lstActivo: lstActivo,
      lstFields: lstFields,
      objForm: {},
    };
  },
  async created() {
    this.handleInitForm();
    this.handleCatalogos();
    this.handleDataInit();
    
  },
  methods: {
    handleInitForm(){
      this.objForm = {
        nombre: "",
        codigo: "",
        descripcion: "",
        sku: "",
        id_categoria_articulo: "",
        activo: "1",
      };
    },
    async handleDataInit(){
      if(this.bEdit && this.strRecordId){
        const objResponse = await axios.get(`${this.strApiRoute}/${this.strRecordId}`);
        this.objForm = objResponse.data;
        this.objForm.id_categoria_articulo = String(this.objForm.id_categoria_articulo);
        this.objForm.activo = this.objForm.activo ? "1" : "0";
      }
    },
    async handleCatalogos(){
      try{
        const objResponse = await axios.get("/categorias-articulos");
        this.lstFields.find(objField => objField.name === 'id_categoria_articulo' ).options = objResponse.data.map(objItem => ({
          id: String(objItem.id_categoria_articulo),
          text: String(objItem.nombre),
        }));
      }catch(e){
        console.log(e);
      }
    },
    handleSubmit(objForm){
      this.$router.go(-1);
      this.handleInitForm();
    },
    handleCancel(){
      this.$router.go(-1);
    }
  }
};
</script>
