<template>
  <physiocloud-component-list
    :bLoading="bLoading"
    :bSelectRow="false"
    :strTitleList="'Pruebas Paciente'"
    :strRoute="'/test-patient'"
    :strPrimaryKey="'id_prueba_paciente'"
    :objPagination="{ pageSize: 15 }"
    :objParamsAddRouter="objParamsAddRouter"
    :lstColumns="lstColumns"
    :lstData="lstData"
    @refresh="handleDataInit"
    @select="handleSelect"
  />
</template>
<script>
import axios from "@/axios";
import { format } from 'date-fns';

const lstColumns = [
  { strTitle: "Prueba", strDataIndex: "strPruebaPaciente", strKey: "strPruebaPaciente", bFiler: true, 
    bUrl: true, strUrl: '/test-patient/edit', strUrlKey: 'id_prueba_paciente' },
  { strTitle: "Extremidad", strDataIndex: "strExtremidad", strKey: "strExtremidad", bFilter: true },
  { strTitle: "Cuerpo", strDataIndex: "strCuerpo", strKey: "strCuerpo", bFilter: true },
  { strTitle: "Prueba", strDataIndex: "strPrueba", strKey: "strPrueba", bFilter: true },
  { strTitle: "Fecha", strDataIndex: "strFechaCreacion", strKey: "strFechaCreacion" },
  { strTitle: "", strDataIndex: "action", strKey: "action", sorter: false, strClass: "text-end" },
];

export default {
  props: {
    id: {
      type: String,
      default: null,
    },
    parentId: {
      type: String,
      default: null,
    },
    recordId: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      bLoading: false,
      objParamsAddRouter: {},
      lstColumns: lstColumns,
      lstData: [],
    };
  },
  async created() {
    this.handleDataInit();
    this.objParamsAddRouter = {
      parentId1: this.parentId,
      parentId2: this.recordId,
    }
  },
  methods: {
    async handleDataInit() {
      try{
        this.bLoading = true;
        const objResponse = await axios.get(`/pacientes/${this.id}/consultas/${this.recordId}/pruebas`);
        this.lstData = objResponse.data.map((objResponse) => ({
          ...objResponse,
          id_extremidad: String(objResponse.id_extremidad),
          id_cuerpo_humano: String(objResponse.id_cuerpo_humano),
          id_prueba_fisica: String(objResponse.id_prueba_fisica),
          strPruebaPaciente: `PXTEST-#${objResponse.id_prueba_paciente}`,
          strExtremidad: objResponse.extremidad?.nombre || '',
          strCuerpo: objResponse.cuerpo_humano?.nombre || '',
          strPrueba: objResponse.prueba_fisica?.nombre || '',
          strFechaCreacion: format(objResponse.fecha_creacion, 'dd/MM/yyyy'),
          objParamsEditRouter: {
            parentId1: this.parentId,
            parentId2: this.recordId,
            recordId: objResponse.id_prueba_paciente,
          },
        }));
      } catch (error) {
        console.error(error);
      } finally {
        this.bLoading = false;
      }
    },
    handleSelect(objRecord) {
      console.log(objRecord);
    },
  }
};
</script>
