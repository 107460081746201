<template>
  <div class="page-wrapper">
    <div class="content">
      <breadcrumb :title="strTitle" :text="strSubutitle" :path="strPath" />
      <div class="row">
        <div class="col-12 col-md-12 col-lg-12 col-xl-12">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <template v-if="strFechaCreacion">
                  <!--div class="col-sm-12 col-12 text-end">
                    <small class="text-muted">Fecha creación : {{ strFechaCreacion }}</small>
                  </div-->
                </template>
                  <div class="col-sm-12">
                    <div class="card card-table show-entire">
                      <div class="card-body">
                        <!-- Table Header -->
                        <div class="page-table-header mb-2">
                          <div class="row align-items-center">
                            <div class="col">
                              <div class="doctor-table-blk">
                                <h3>Consultas</h3>
                                <div class="doctor-search-blk">
                                  <div class="top-nav-search table-search-blk">
                                    <form @submit.prevent="handleSearch">
                                      <input
                                        type="text"
                                        class="form-control"
                                        placeholder="Buscar consulta"
                                        v-model="strSearchQuery"
                                      />
                                      <a class="btn"
                                        ><img src="@/assets/img/icons/search-normal.svg" alt=""
                                      /></a>
                                    </form>
                                  </div>
                                  <div class="add-group">
                                    <router-link
                                      :to="{ name: 'add-consultation', params: { id: id, parentId: parentId } }"
                                      class="btn btn-primary add-pluss ms-2"
                                      ><img src="@/assets/img/icons/plus.svg" alt=""
                                    /></router-link>
                                    <a
                                      href="javascript:;"
                                      class="btn btn-primary doctor-refresh ms-2"
                                      @click="getConsultations"
                                      ><img src="@/assets/img/icons/re-fresh.svg" alt=""/>
                                    </a>
                                    <a
                                      v-if="lstData.length"
                                      class="btn btn-primary doctor-refresh ms-2"
                                      variant="info mt-1 me-1"
                                      data-bs-toggle="modal"
                                      data-bs-target="#info-alert-modal">
                                      <img src="@/assets/img/icons/bar-icon.svg" alt=""/>
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- /Table Header -->

                        <!-- Table -->
                        <div class="table-responsive">
                          <a-table
                            class="table border-0 custom-table comman-table datatable mb-0"
                            :columns="lstColumns"
                            :dataSource="handleFilterData"
                            :rowKey="record => record.id_consulta"
                            :pagination="{ pageSize: 15 }"
                            :loading="bSpinner">
                          <!-- :rowSelection="handleRowSelection" -->
                            <template #bodyCell="{ column, record }">
                              <template v-if="column.key === 'id_consulta'">
                                <router-link :to="{ name: 'detail-consultation', params: {id: record.id_paciente, parentId: record.id_historia_clinica, recordId: record.id_consulta}}">
                                  CONS-#{{ record.id_consulta }}
                                </router-link>
                              </template>
                              <template v-else-if="column.key === 'action'">
                                <div class="text-end">
                                  <div class="dropdown dropdown-action">
                                    <a
                                      href="javascript:;"
                                      class="action-icon dropdown-toggle"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      <i class="fa fa-ellipsis-v"></i>
                                    </a>
                                    <div class="dropdown-menu dropdown-menu-end">
                                      <router-link class="dropdown-item" 
                                        :to="{ name: 'edit-consultation', params: {id: record.id_paciente, parentId: record.id_historia_clinica, recordId: record.id_consulta}}">
                                        <i class="fa-solid fa-pen-to-square m-r-5"></i> Editar
                                      </router-link>
                                      <!--a class="dropdown-item"
                                        href="javascript:;"
                                        data-bs-toggle="modal"
                                        data-bs-target="#delete_patient">
                                        <i class="fa fa-trash-alt m-r-5"></i> Eliminar
                                      </a-->
                                    </div>
                                  </div>
                                </div>
                              </template>
                            </template>
                          </a-table>
                        </div>
                        <!-- /Table -->
                      </div>
                    </div>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div id="info-alert-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="standard-modalLabel"
    aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="standard-modalLabel">Progreso del paciente</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close">
          </button>
        </div>
        <div class="modal-body">
          <div class="chart-title patient-visit mb-0">
            <div class="average-health">
              <h5>{{ strPromedio }} <span>promedio</span></h5>
            </div>
          </div>
          <div id="health-chart">
            <apexchart
              type="line"
              height="400"
              :options="healthChart.chart"
              :series="healthChart.series">
            </apexchart>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import axios from "@/axios";
  import { format } from 'date-fns';
  import { ProgressPatientChart } from "./ProgressChart.js";

  function createColumns(lstColumns) {
    return lstColumns.map((objColumn) => ({
      title: objColumn.strTitle,
      dataIndex: objColumn.strDataIndex,
      key: objColumn.strKey || objColumn.strDataIndex,
      sorter: objColumn.bSorter !== false ? {
        compare: (a, b) => {
          const aValue = a[objColumn.strDataIndex];
          const bValue = b[objColumn.strDataIndex];

          // Verificar si los valores son cadenas y convertir a minúsculas
          const aValueStr = typeof aValue === 'string' ? aValue.toLowerCase() : aValue;
          const bValueStr = typeof bValue === 'string' ? bValue.toLowerCase() : bValue;

          // Comparar valores
          if (aValueStr < bValueStr) return -1;
          if (aValueStr > bValueStr) return 1;
          return 0;
        },
      } : undefined,
      class: objColumn.strClass || "",
    }));
  }

  const lstColumnsToCreate = [
    { strTitle: "Consulta", strDataIndex: "id_consulta", strKey: "id_consulta" },
    { strTitle: "Tipo Consulta", strDataIndex: "strTipoConsulta", strKey: "strTipoConsulta", bFilter: true },
    { strTitle: "Tratamiento", strDataIndex: "strTratamiento", strKey: "strTratamiento", bFilter: true },
    { strTitle: "Escala dolor", strDataIndex: "escala_dolor", strKey: "escala_dolor", bFilter: true },
    { strTitle: "Firma", strDataIndex: "firmado", strKey: "firmado", bFilter: true },
    { strTitle: "Fecha tratamiento", strDataIndex: "strFechaTratamiento", strKey: "strFechaTratamiento" },
    { strTitle: "", strDataIndex: "action", strKey: "action", sorter: false, strClass: "text-end" },
  ];

  const lstRowSelected = [];
  const lstColumns = createColumns(lstColumnsToCreate);
  const lstData = [];
  export default {
    props: {
      id: {
        type: String,
        default: null,
      },
      parentId: {
        type: String,
        default: "",
      },
    },
    emits: ["update:modelValue", "select"],
    data() {
      return {
        bSpinner: false,
        healthChart: ProgressPatientChart,
        strTitle: "Paciente",
        strSubutitle: `Historia Clínica #${this.parentId}`,
        strPath: `/patient/${this.id}/profile`,
        strPromedio: 0,
        strFechaCreacion: "",
        strSearchQuery: "",
        lstData,
        lstColumns,
        lstRowSelected,
        lstColumnsToCreate,
      };
    },
    computed: {
      handleFilterData() {
      if (!this.strSearchQuery) return this.lstData;
        const query = this.strSearchQuery.toLowerCase();

        return this.lstData.filter((objData) => {
          return this.lstColumnsToCreate.some((objColumn) => {
            if (!objColumn.bFilter) return false;
            const strValue = objData[objColumn.strDataIndex];
            const strValueSearch = (typeof strValue === 'string' && strValue !== null) ? strValue.toLowerCase() : '';
            return strValueSearch.includes(query);
          });
        });
      },
      handleRowSelection(){
        return {
          selectedRowKeys: this.lstRowSelected,
          onChange: this.handleRowSelectionChange,
        }
      }
    },
    async created() {
      await this.getConsultations();
      await this.handlePaciente();
      this.getDataDashboard();
    },
    methods: {
      async getConsultations() {
        this.bSpinner = true;
        try {
          const objResponse = await axios.get(`/historias-clinicas/${this.parentId}/consultas`);
          this.strFechaCreacion = objResponse.data[0] && objResponse.data[0].fecha_creacion ? format(new Date(objResponse.data[0].fecha_creacion), 'dd/MM/yyyy') : '';
          this.lstData = objResponse.data.map((objHistoria) => {
            this.strTitle = `${objHistoria.paciente?.nombre ?? ''} ${objHistoria.paciente?.apellido_paterno ?? ''} ${objHistoria.paciente?.apellido_materno ?? ''}`;
            const dtFechaTratamiento = objHistoria.fecha_tratamiento ? new Date(objHistoria.fecha_tratamiento).toISOString().split('T')[0] : 'No disponible';
            return {
              ...objHistoria,
              strTratamiento: objHistoria.tratamiento?.nombre || "",
              strTipoConsulta: objHistoria.tipo_consulta?.nombre || "",
              strFechaTratamiento: dtFechaTratamiento,
            };
          });
        } catch (error) {
          console.error('Error fetching data:', error.message);
        }
        this.bSpinner = false;
      },
      async handlePaciente(){
        try{
          if(this.lstData.length == 0){
            const objResponse = await axios.get(`/pacientes/${this.id}/info-personal`);
            this.strTitle = `${objResponse.data.nombre ?? ''} ${objResponse.data.apellido_paterno ?? ''} ${objResponse.data.apellido_materno ?? ''}`;
          }
        }catch(e){
          console.log(e);
        }
      },
      async getDataDashboard() {
        if(!this.lstData.length) return;
        try {
          const objResponse = await axios.get(`/historias-clinicas/${this.parentId}/consultas/dashboard/progreso`);
          this.strPromedio = parseFloat(objResponse.data.promedioEscalaDolor).toFixed(2);
          const objSeries = {
            name: "Dolor",
            color: "#00D3C7",
            data: objResponse.data.escalasDolor,
          };
          
          this.healthChart.chart.xaxis.categories = objResponse.data.fechas;
          this.healthChart.series = [objSeries];

        } catch (error) {
          console.error('Error fetching data:', error.message);
        }
      },
      handleRowSelectionChange(selectedRowKeys) {
        this.lstRowSelected = selectedRowKeys;
      },
      handleSearch() {
        // Este método se llama automáticamente debido a la propiedad computada `filteredData`
      },
    }
  };
</script>
